import { environment } from '../../../environments/environment';

export const Url = {
    USER: environment.api + '/sequation-user-api',
    AUTHENTICATION: environment.api + '/sequation-user-api',
    CONTENT_LIBRARY: environment.api + '/sequation-content-management-v2-api',
    PROFILE: environment.api + '/sequation-usermanagement-composer-api',
    USER_MANAGEMENT: environment.api + '/sequation-user-management-api',
    ASSESSMENT:  environment.api+'/sequation-community-assessment-api',
    PLAN: environment.api + '/sequation-plan-v2-api',
    PLANv2: environment.api + '/sequation-plan-v2-api',
    JOBS: environment.api + '/sequation-job-v2-api',
    SRM: environment.api + '/sequation-srm-v2-api',
    CVB: environment.api + '/sequation-cv-builder-v2-api',
    DOCUMENT: environment.api +'/sequation-document-v2-api',
    ORGANIZATION: environment.api + '/sequation-organization-v2-api',
    ANALYTICS:  environment.api +'/sequation-analytics-v2-api',
};
